<template>
  <section class="xbts-asset-detail" v-if="symbol">
    <div class="card w-100 h-100" v-if="asset">
      <div class="card-body asset-detail">
        <div class="row">
          <div class="col-md-2 text-center">
            <img v-if="isKnownAsset(symbol)" :src="'images/assets/' + symbol.toLowerCase() + '.png'" :alt="symbol"/>
            <vue-letter-avatar v-else :name="assetToSymbol(symbol)" size='40' :rounded=true />
            <h4 class="card-title mt-2">
              <span class="small font-weight-lighter">{{ prefix }}.</span>&nbsp;
              <span class="text-white">{{ symbol }}</span> {{ asset.for_liquidity_pool }}
              <span v-show="!asset.for_liquidity_pool">{{asset.id}}</span>
            </h4>
          </div>

          <div class="col-md-10">
            <p v-if="asset">
              {{ asset.options.description.main }}
            </p>
            <p v-if="allAssets[symbol]">
              <a class="btn btn-info" target="_blank" :href="'https://ex.xbts.io/market/'+ symbol + '_' + market">
                DEX MARKET
              </a>
              <router-link v-show="search[assetToSymbol(symbol)]" class="ml-2 btn btn-info" :to="'/pools/?a=' + assetToSymbol(symbol)">DeFi {{assetToSymbol(symbol)}}</router-link>
            </p>
          </div>

        </div>

      </div>
    </div>

    <b-tabs class="w-100 mt-3">
      <b-tab active>
        <template slot="title">
          {{ $t('assets.asset_info') }}
        </template>
        <div class="row" v-if="asset">
          <div class="col-md-6">
            <div class="card w-100">
              <div class="card-body">
                <h4 class="card-title" v-if="asset">
                  <img v-if="isKnownAsset(symbol)" :src="'images/assets/' + symbol.toLowerCase() + '.png'" width="20px"
                       height="20px"/>
                  <vue-letter-avatar v-else :name="assetToSymbol(symbol)" size='20' :rounded=true />
                  <span class="ml-2 text-white"
                        v-if="asset.options.description">{{ asset.options.description.short_name }}</span>
                  <span class="ml-2 text-white" v-if="!asset.options.description">{{ symbol }}</span>
                </h4>

                <table class="table table-striped table-borderless">
                  <tbody>
                  <tr>
                    <td>{{ $t('assets.asset_type') }}</td>
                    <td>{{ type.toUpperCase() }}</td>
                  </tr>
                  <tr v-if="issuer.name">
                    <td>{{ $t('assets.issuer') }}</td>
                    <td>
                      <router-link :to="'/account/'+issuer.name">{{ issuer.name }}</router-link>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('assets.precision') }}</td>
                    <td>{{ asset.precision }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('assets.market_fee') }}</td>
                    <td v-if="asset.options.market_fee_percent">{{ asset.options.market_fee_percent / 100 }}%</td>
                    <td v-if="!asset.options.market_fee_percent">0%</td>
                  </tr>
                  <tr v-if="asset.options.extensions.reward_percent">
                    <td>Market fee referral reward
                      <i class="icon-info text-info"
                         title="If a market fee is set, the reward percent indicates how much of that market fee is shared through the referral rewards program instead of only benefitting the asset owner."
                         v-b-tooltip.html.top></i>
                    </td>
                    <td>{{ asset.options.extensions.reward_percent / 100 }}%</td>
                  </tr>
                  </tbody>
                </table>
                <p class="mt-3" v-if="flags">
                                    <span class="mr-1 mb-1 badge badge-primary" v-bind:key="key"
                                          v-for="(item, key) in flags"
                                          v-show="item">{{ $t('assets.' + key).toUpperCase() }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card w-100 h-100">
              <div class="card-body">
                <h4 class="card-title text-white">Permissions</h4>

                <table class="table table-striped table-borderless">
                  <tbody>
                  <tr v-if="asset.options">
                    <td>Max market fee</td>
                    <td>{{ asset.options.max_market_fee / 10 ** asset.precision }}</td>
                  </tr>

                  <tr v-if="asset.options">
                    <td>Max supply</td>
                    <td>{{ asset.options.max_supply / 10 ** asset.precision }}</td>
                  </tr>

                  <tr>
                    <td>Current supply</td>
                    <td>{{ asset_dyn.current_supply / 10 ** asset.precision }}</td>
                  </tr>
                  <tr>
                    <td>Stealth supply</td>
                    <td>{{ asset_dyn.confidential_supply / 10 ** asset.precision }}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>

        <!-- Lists -->

        <div class="row mt-3" v-if="asset">
          <!-- blacklist -->
          <div class="col-lg-6 grid-margin stretch-card">
            <div class="card" v-if="blacklist_markets.length || blacklist_authorities.length">
              <div class="card-body">
                <h4 class="card-title text-white">{{ $t('assets.black_lists') }}</h4>
                <div class="accordion accordion-bordered" role="tablist">
                  <b-card no-body v-if="blacklist_markets.length">
                    <b-card-header header-tag="header" role="tab">
                      <a href="javascript:void(0);" v-b-toggle.blacklist_markets>Black List
                        Markets</a>
                    </b-card-header>
                    <b-collapse accordion="my-accordion" id="blacklist_markets" role="tabpanel">
                      <b-card-body>
                        <div class="w-100">
                          <router-link :to="'/asset/' + item.symbol"
                                       class="m-1 badge badge-dark"
                                       v-bind:key="item.id" v-for="item in blacklist_markets">
                            {{ item.symbol }}
                          </router-link>


                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body v-if="blacklist_authorities.length">
                    <b-card-header header-tag="header" role="tab">
                      <a
                          href="javascript:void(0);"
                          v-b-toggle.blacklist_authorities
                      >Black List Authorities</a>
                    </b-card-header>
                    <b-collapse accordion="my-accordion" id="blacklist_authorities" role="tabpanel">
                      <b-card-body>
                        <div class="w-100">
                                            <span class="m-1 badge badge-dark" v-bind:key="item.id"
                                                  v-for="item in blacklist_authorities">
                                                <span v-if="item.name">{{ item.name }}</span>
                                            </span>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                </div>
              </div>
            </div>
          </div>

          <!-- whitelist -->
          <div class="col-lg-6 grid-margin stretch-card"
               v-if="whitelist_markets.length || whitelist_authorities.length">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title text-white">{{ $t('assets.white_lists') }}</h4>
                <div class="accordion accordion-bordered" role="tablist">
                  <b-card no-body v-if="whitelist_markets.length">
                    <b-card-header header-tag="header" role="tab">
                      <a
                          href="javascript:void(0);"
                          v-b-toggle.whitelist_markets
                      >White List Markets</a>
                    </b-card-header>
                    <b-collapse accordion="my-accordion" id="whitelist_markets" role="tabpanel">
                      <b-card-body>
                        <div class="w-100">
                                            <span class="m-1 badge badge-primary" v-bind:key="item.id"
                                                  v-for="item in whitelist_markets">
                                                {{ item.symbol }}
                                            </span>

                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body v-if="whitelist_authorities.length">
                    <b-card-header header-tag="header" role="tab">
                      <a
                          href="javascript:void(0);"
                          v-b-toggle.whitelist_authorities
                      >White List Authorities</a>
                    </b-card-header>
                    <b-collapse accordion="my-accordion" id="whitelist_authorities" role="tabpanel">
                      <b-card-body>
                        <div class="w-100">
                                            <span class="m-1 badge badge-dark" v-bind:key="item.id"
                                                  v-for="item in whitelist_authorities">
                                                <span v-if="item.name">{{ item.name }}</span>
                                            </span>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                </div>
              </div>
            </div>
          </div>

        </div>
      </b-tab>

      <b-tab v-if="holders.symbol">
        <template slot="title">
          {{ $t('assets.top_holders') }}
        </template>

        <div class="card">
          <div class="card-body">

            <div class="w-100">
              <table class="table table-striped table-borderless">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Account</th>
                  <th>Amount</th>
                  <th>Amount {{ currSymbols[currency] }}</th>
                  <th>%</th>
                </tr>
                </thead>
                <tbody>
                <tr v-bind:key="item.account_id" v-for="(item, idx) in holders.holders">
                  <td>{{ idx + 1 }}</td>
                  <td class="text-white">
                    {{ item.name }}
                  </td>
                  <td class="text-info">
                    {{ item.amount }} <span class="small">{{ holders.symbol }}</span>
                  </td>
                  <td class="text-success">
                    {{ currSymbols[currency] }} {{ calcTotalAmount(item.amount) }}
                  </td>
                  <td class="text-white">
                    {{ calcUserPercent(item.amount).toFixed(4) }}%
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </b-tab>
    </b-tabs>


  </section>
</template>

<script>
import {currSymbols, permission_flags, uia_permission_mask, search} from '@/config';
//import global from "@/mixins/in";

export default {
  name: "Asset",
  //mixins: [global],
  data() {
    return {
      currSymbols: currSymbols,
      search: search,
      prefix: '',
      symbol: 'STH',
      asset: null,
      type: 'uia',
      blacklist_markets: [],
      blacklist_authorities: [],
      whitelist_markets: [],
      whitelist_authorities: [],
      asset_dyn: {},
      issuer: '',
      flags: {},
      holders: [],
      assetLP: null,
      market: "XBTSX.USDT",
      allAssets: {},

    }
  },
  computed: {
    prices() {
      return this.$store.getters['xbts/featuredPrice']['bts'];
    },
    currency() {
      return this.$store.getters['paprika/currency'];
    },
  },
  watch: {
    '$route.params.symbol': {
      handler: async function (symbol) {
        await this.getAssetData(symbol);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    calcUserPercent(userAmount) {
      return userAmount / this.assetLP.SHARE.supply * 100;
    },
    calcTotalAmount(userAmount) {
      const percent = this.calcUserPercent(userAmount);
      return ((this.assetLP.SHARE.amount * this.prices[this.currency].price) / 100 * percent).toFixed(4);
    },
    async getAssetData(assetSymbol) {
      const symbol = assetSymbol.split('.');
      //this.prefix = symbol.length > 1 ? symbol[0] : '';
      //this.symbol = symbol.length > 1 ? symbol[1] : symbol[0];
      this.symbol = assetSymbol;
      if (this.market === this.symbol) {
        this.market = 'BTS';
      }

      this.asset = await this.$store.dispatch('assets/getAsset', assetSymbol);

      this.asset_dyn = (await this.$store.dispatch('app/getObjects', [this.asset.dynamic_asset_data_id]))[0];

      this.issuer = (await this.$store.dispatch('app/getObjects', [this.asset.issuer]))[0];

      if (this.asset.bitasset_data_id) {
        this.type = 'smart';
      }

      if (this.asset.for_liquidity_pool) {
        this.type = 'defi';
      }

      if (this.asset.symbol === 'BTS') {
        this.type = 'core';
      }

      if (this.asset.options.blacklist_markets.length) {
        this.blacklist_markets = await this.$store.dispatch('app/getObjects', this.asset.options.blacklist_markets);
      }

      if (this.asset.options.blacklist_authorities.length) {
        this.blacklist_authorities = await this.$store.dispatch('app/getObjects', this.asset.options.blacklist_authorities);
      }

      if (this.asset.options.whitelist_markets.length) {
        this.whitelist_markets = await this.$store.dispatch('app/getObjects', this.asset.options.whitelist_markets);
      }

      if (this.asset.options.whitelist_authoritieslength) {
        this.whitelist_authorities = await this.$store.dispatch('app/getObjects', this.asset.options.whitelist_authorities);
      }

      try {
        this.holders = await this.$store.dispatch('assets/getHolders', this.asset.symbol);
      } catch (e) {

      }

      this.flags = await this.getFlagBooleans(this.asset.options.flags);

      this.assetLP = await this.$store.dispatch('lp/LpSingle', this.asset.symbol);


    },
    async getFlagBooleans(mask, isBitAsset = false) {
      let booleans = {
        charge_market_fee: false,
        white_list: false,
        override_authority: false,
        transfer_restricted: false,
        disable_force_settle: false,
        global_settle: false,
        disable_confidential: false,
        witness_fed_asset: false,
        committee_fed_asset: false
      };

      if (mask === "all") {
        for (let flag in booleans) {
          if (
              !isBitAsset &&
              uia_permission_mask.indexOf(flag) === -1
          ) {
            delete booleans[flag];
          } else {
            booleans[flag] = true;
          }
        }
        return booleans;
      }

      for (let flag in booleans) {
        if (
            !isBitAsset &&
            uia_permission_mask.indexOf(flag) === -1
        ) {
          delete booleans[flag];
        } else {
          if (mask & permission_flags[flag]) {
            booleans[flag] = true;
          }
        }
      }

      return booleans;
    }
  },
  async created() {
    //await this.getAssetData();
    this.allAssets = await this.knownAssets();
  }
}
</script>

<style scoped>
.asset-detail img {
  max-width: 64px;
}
</style>
